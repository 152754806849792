// Footer.jsx
import React from 'react';
import LogoImage from "../../assets/Group 68.svg"; // Importing the logo
import { FaPhone, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa'; // Import phone, email, and location icons

import './Footer.css';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="logo-container">
          <img src={LogoImage} alt="Company Logo" className="footer-logo" />
          <p>&copy; {currentYear} Company. All rights reserved.</p>
        </div>
        <div className="footer-content">
          <p>Senior Full Stack Developer (Web & Mobile): Harut Azatyan</p>
          <div className="location-info">
            <FaMapMarkerAlt className="location-icon" />
            <p>Location: Yerevan, Armenia</p>
          </div>
          <div className="contact-info">
            <div className="contact-item">
              <a href="tel:+37496454503" className="contact-link">
                <FaPhone className="contact-icon" />
                <p>+37496454503</p>
              </a>
            </div>
            <div className="contact-item">
              <a href="mailto:harutazatyan45@gmail.com" className="contact-link">
                <FaEnvelope className="contact-icon" />
                <p>harutazatyan45@gmail.com</p>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="social-icons">
        {/* You can add social icons here if needed */}
      </div>
    </footer>
  );
};

export default Footer;
