import React from "react";
import "../../styles/components/pages/AboutMe.css";

const AboutMe = () => {
  return (
<div>
  
<section className="section about" id="about">
      <div className="about-container">
        <div className="about-content">
          <div className="about-picture-container">
            <div className="about-picture">
              <a href="/">
                <img src="img/my.png" alt="myphoto" className="myimg" />
              </a>
            </div>
          </div>

          <div className="about-text-container">
            <div className="about-heading">ABOUT ME</div>
            <div className="about-description">
              Hi, I'm Harut Azatyan, a seasoned full-stack web developer with a
              strong passion for coding and problem-solving. I specialize in
              creating web solutions that seamlessly blend functionality with
              aesthetics, aiming to enhance user experiences.
            </div>
            <div className="about-details">
              <p>
                I have extensive experience working collaboratively with
                brands and agencies worldwide. My focus is on designing and
                developing websites and applications that prioritize
                interaction, motion, and a visually compelling user experience.
              </p>
              <p>
                As a dedicated freelance developer, I am committed to delivering
                high-quality solutions tailored to the unique needs of each
                project. My goal is to contribute to making the web a better
                place by crafting elegant and efficient code.
              </p>
            </div>
            <div className="skills-section">
              <div className="skills-heading">Skills:</div>
              <ul className="skills-list">
                <li>JavaScript (React, Node.js)</li>
                <li>HTML5, CSS3 (Sass/SCSS)</li>
                <li>RESTful APIs and GraphQL</li>
                <li>Database Design (MongoDB, MySQL)</li>
                <li>Version Control (Git, GitHub)</li>
                <li>Responsive Web Design</li>
                <li>UI/UX Design Principles</li>
                <li>Agile Development Methodology</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    
</div>

  );
};

export default AboutMe;
