import React, { useState } from 'react';
import QRCodeReact from 'qrcode.react';
import html2canvas from 'html2canvas';
import jsQR from 'jsqr';

import './QRCode.css';

function QRCode() {
  const [data, setData] = useState('');
  const [showQRCode, setShowQRCode] = useState(false);
  const [qrCodeSize, setQrCodeSize] = useState(128);
  const [foregroundColor, setForegroundColor] = useState('#000000'); // Default is black
  const [backgroundColor, setBackgroundColor] = useState('#ffffff'); // Default is white

  const handleChange = (e) => {
    setData(e.target.value);
  };

  const handleSizeChange = (e) => {
    setQrCodeSize(parseInt(e.target.value, 10));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    // Reset data, QR code display
    setData('');
    setShowQRCode(false);



    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const image = new Image();
        image.src = event.target.result;
        image.onload = () => {
          const canvas = document.createElement('canvas');
          canvas.width = image.width;
          canvas.height = image.height;
          const context = canvas.getContext('2d');
          context.drawImage(image, 0, 0, image.width, image.height);

          const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
          const code = jsQR(imageData.data, canvas.width, canvas.height);

          if (code) {
            setData(code.data);
            setShowQRCode(true);
          } else {
            console.error('Unable to decode QR code from the provided image.');
          }
        };
      };
      reader.readAsDataURL(file);
    }
  };

  const handleGenerateQR = (e) => {
    e.preventDefault();
    setShowQRCode(true);
  };


  const handleForegroundChange = (e) => {
    setForegroundColor(e.target.value);
  };

  const handleBackgroundChange = (e) => {
    setBackgroundColor(e.target.value);
  };

  const handleDownloadQR = () => {
    if (showQRCode) {
      const qrCodeContainer = document.getElementById('qr-code-container');

      if (qrCodeContainer) {
        html2canvas(qrCodeContainer, { scale: 2 })
          .then((canvas) => {
            const dataUrl = canvas.toDataURL('image/png');
            const link = document.createElement('a');
            link.href = dataUrl;
            link.download = 'qrcode.png';
            link.click();
          })
          .catch((error) => {
            console.error('Error during html2canvas operation:', error);
          });
      } else {
        console.error('QR code container not found');
      }
    }
  };



  return (
    <div className="qrpage">
  
      <div className="QRCode-container">
        <div className="Project-description">
          <p>
            Generate and download QR codes or upload a QR code image to decode and display the corresponding data.
            Enhance user interaction and experience with this versatile QR code component.
          </p>
          <p>
          QR Code Generator: Easily Create Custom QR Codes Online"

Description:
"Unlock the power of instant information sharing with our user-friendly QR Code Generator! Transform text, URLs, contact details, and more into unique QR codes effortlessly. Customize colors and styles to match your brand or personal style. Generate, download, and share your QR codes in seconds, simplifying communication in the digital age. Start creating your custom QR codes today!
          </p>
        </div>
        <div className="QRCode-input-container">
          <input
            type="text"
            value={data}
            onChange={handleChange}
            placeholder="Enter Your Website or text"
            className="QRCode-input"
          />
        </div>
        <div className="QRCode-size-container">
          <label htmlFor="qrCodeSize">Size:</label>
          <input
            type="number"
            id="qrCodeSize"
            value={qrCodeSize}
            onChange={handleSizeChange}
            min="1"
            step="1"
            className="QRCode-size-input"
          />
        </div>
        <div className="QRCode-file-container">
          <label htmlFor="qrCodeFile">Upload QR Code Image:</label>
          <input
            type="file"
            id="qrCodeFile"
            accept="image/*"
            onChange={handleFileChange}
            className="QRCode-file-input"
          />
        </div>
      
        <div className="button-container">
          <button onClick={handleGenerateQR} className="GenerateQR-button">
            Generate QR
          </button>
          <button onClick={handleDownloadQR} className="DownloadQR-button">
            Download QR
          </button>
        </div>

        <div className="QRCode-color-container">
        <label htmlFor="foreground">Foreground Color:</label>
        <input
          type="color"
          id="foreground"
          value={foregroundColor}
          onChange={handleForegroundChange}
          className="QRCode-color-input"
        />
      </div>
      <div className="QRCode-color-container">
        <label htmlFor="background">Background Color:</label>
        <input
          type="color"
          id="background"
          value={backgroundColor}
          onChange={handleBackgroundChange}
          className="QRCode-color-input"
        />
      </div>

        {showQRCode && data && (
          <div className="qr-code-container" id="qr-code-container">
          <QRCodeReact value={data} size={qrCodeSize} fgColor={foregroundColor} bgColor={backgroundColor} className="QRCode-image" />
          <p className="QRCode-text">Scan the QR code</p>
        </div>
        )}
        
        <a href="/" className="Home-button">
          Go to Home
        </a>
      </div>
    </div>
  );
}

export default QRCode;
