// About.js

import React from 'react';
import './Aboute.css';

function About() {
  return (
    <div className="about-container" id="about">
      <div className="about-content">
        <div className="about-item">
          <h3>Freelance Developer</h3>
          <p>I am a freelance developer with over two years of industry experience, gaining valuable expertise along the way.</p>
        </div>
        <div className="about-item">
          <h3>100+ Projects Successfully Completed</h3>
          <p>I take pride in successfully completing over 100 projects, delivering high-quality solutions to my clients.</p>
        </div>
        <div className="about-item">
          <h3>Expertise Across Various Technologies</h3>
          <p>I have experience in a wide range of technologies and tools, ensuring versatile solutions.</p>
        </div>
        <div className="about-item">
          <h3>40+ Satisfied Clients</h3>
          <p>I have built strong relationships with over 40 satisfied clients through dedication and professionalism.</p>
        </div>
      </div>
    </div>
  );
}

export default About;
