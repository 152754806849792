import React, { useState } from 'react';
import { motion, AnimatePresence } from "framer-motion";

// Define or import the slideAnimation function
// Example definition:
function slideAnimation(direction) {
  return {
    initial: { opacity: 0, x: direction === 'left' ? -100 : 100 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: direction === 'left' ? -100 : 100 },
  };
}

const HomeText = () => {
  const [isVisible] = useState(true);

  const animationProps = slideAnimation('left');

  return (
    <div className="welcome-header1">
      <AnimatePresence>
        {isVisible && (
          <motion.div {...animationProps} key="hello">
            <div className="hello">Hello,</div>
            <div className="im-harut">
              <p className="im">I'm </p>
              <p className="harut">Harut</p>
            </div>
            <p className="full">-a full stack</p>
            <p className="web">web developer</p>
            <p className='offer'>
              Whether you need a dynamic website, a robust backend system, or a seamless user experience, I am here to help you bring your vision to life. Let's work together to create something amazing!
            </p>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default HomeText;
