import React, { useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// Import components
import Home from './pages/Home/Home';
import AboutMe from './pages/AboutMe/AboutMe';
import MyProjects from './pages/MyProjects/MyProjects';
import Services from './pages/Services/Services';
import BlogHome from './pages/Blog/BlogHome';
import Login from './pages/Login/Login';
import Registration from './pages/Registration/Registration';
import AddPost from './pages/AddPost/AddPost';
import FullPost from './pages/FullPost/FullPost'; 
import Tags from './pages/Tags/tags'

// Redux actions and selectors
import { fetchAuthMe, selectIsAuth } from './redux/slices/auth';
import PostEdit from './components/Post/PostEdit';
import QrCode from './components/QrCode/QRCode'
import UserAccount from './components/UserInfo/UserAccount';
import ServicesPage from './pages/Services/ServicesPage';
const RoutesConfig = () => {
  const dispatch = useDispatch();
  const isAuth = useSelector(selectIsAuth);

  useEffect(() => {
    dispatch(fetchAuthMe());
  }, [dispatch]);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<AboutMe />} />
      <Route path="/myprojects" element={<MyProjects />} />
 
      <Route path="/services-page" element={<ServicesPage />} />
      <Route path="/services" element={<Services />} />
      <Route path="/blogHome" element={<BlogHome />} /> {/* Fixed path */}
      <Route path="/login" element={isAuth ? <Navigate to="/blogHome" /> : <Login />} />
      <Route path="/register" element={isAuth ? <Navigate to="/blogHome" /> : <Registration />} />
      <Route path="/add-post" element={isAuth ? <AddPost /> : <Navigate to="/login" />} />
      <Route path="/posts/:id" element={<FullPost />} />
      <Route path="/posts/:id/edit" element={<PostEdit />} />
      <Route path="/QrCode" element={<QrCode />} />
      <Route path="/user/:userId" element={<UserAccount />} />
      <Route path="/tags/:tagName" element={<Tags />} />
    </Routes>
  );
};

export default RoutesConfig;
