import React from 'react';
import './MyProjects.css'; // Import your CSS file
 import Project from '../../components/ProjectCard/Projects';
import AboutButton from '../Home/AboutBottun';



const MyProjects = () => {


  return (
    <div className='myproject'>
      <div className='myproject-container'>
        <div className='pole-1'>
          <div className='myprojects-text'>
          my projects
          </div>
          <div className='myprojects-abouteme-container'>
          <div className="back-button">
      <button onClick={() => window.history.back()}>Back</button>
    </div>
          <div className='myprojects-abouteme-button'>
            <AboutButton />
            </div> 
            </div>
        </div>
        <div className='pole-2'>
          <div className='projects-container'>
            <Project />
            </div>
      
       
     
        </div>
        <div className='pole-4'></div>
      </div>
    </div>
  );
};

export default MyProjects;
