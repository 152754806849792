import React from 'react';
import './Services.css';

const servicesData = [
  {
    id: 1,
    title: 'Front-End Development',
    description: 'Building responsive and dynamic user interfaces using modern frameworks like React, Vue, and Angular.',
    examples: [
      'Developing single-page applications (SPAs)',
      'Creating interactive user interfaces',
      'Implementing responsive designs',
    ],
    icon: '🖥️',
  },
  {
    id: 2,
    title: 'Back-End Development',
    description: 'Creating robust and scalable back-end services with Node.js, Express, and various databases like MongoDB, PostgreSQL.',
    examples: [
      'Building RESTful APIs',
      'Database design and management',
      'Server-side logic implementation',
    ],
    icon: '🔧',
  },
  {
    id: 3,
    title: 'Full-Stack Development',
    description: 'Providing end-to-end development solutions, integrating both front-end and back-end to deliver complete web applications.',
    examples: [
      'Developing full-stack web applications',
      'Integration of front-end and back-end services',
      'End-to-end testing and deployment',
    ],
    icon: '🌐',
  },
  {
    id: 4,
    title: 'API Development',
    description: 'Designing and implementing RESTful and GraphQL APIs for seamless data exchange between client and server.',
    examples: [
      'Creating custom RESTful APIs',
      'Implementing GraphQL endpoints',
      'API documentation and versioning',
    ],
    icon: '🔗',
  },
];

const servicesData2 = [
  {
    id: 5,
    title: 'Testing & Debugging',
    description: 'Ensuring code quality through comprehensive testing and debugging practices to deliver reliable applications.',
    examples: [
      'Unit and integration testing',
      'Debugging front-end and back-end issues',
      'Performance optimization',
    ],
    icon: '✔️',
  },
  {
    id: 6,
    title: 'Consultation',
    description: 'Providing expert advice on project planning, architecture, and technology stacks to optimize your development process.',
    examples: [
      'Project planning and estimation',
      'Technical stack selection',
      'Architecture design and review',
    ],
    icon: '💡',
  },
  {
    id: 7,
    title: 'DevOps',
    description: 'Implementing CI/CD pipelines, automating deployments, and managing cloud infrastructure using tools like Docker, Kubernetes, and AWS.',
    examples: [
      'Setting up continuous integration and deployment',
      'Infrastructure as Code (IaC)',
      'Monitoring and logging',
    ],
    icon: '⚙️',
  },
  {
    id: 8,
    title: 'UI/UX Design',
    description: 'Creating intuitive and engaging user experiences with a focus on usability, accessibility, and visual appeal.',
    examples: [
      'Designing wireframes and prototypes',
      'Conducting user research and usability testing',
      'Creating style guides and design systems',
    ],
    icon: '🎨',
  },
];

const ServiceCard = ({ service }) => (
  <div className="service-card">
    <div className="icon">{service.icon}</div>
    <h3>{service.title}</h3>
    <p>{service.description}</p>
    <ul>
      {service.examples.map((example, index) => (
        <li key={index}>{example}</li>
      ))}
    </ul>
  </div>
);

const Services = () => (
  <section id="services" className="services-section">
    <h2>My Services</h2>
    <div className="services-container">
      {servicesData.map(service => (
        <ServiceCard key={service.id} service={service} />
      ))}
      {servicesData2.map(service => (
        <ServiceCard key={service.id} service={service} />
      ))}
    </div>
  </section>
);

export default Services;
