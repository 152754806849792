import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ProjectCard from './ProjectCard';
import './Projects.css';

const ProjectList = ({ items, itemsPerPage }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [isPaused, setIsPaused] = useState(false);
  const totalPages = Math.ceil(items.length / itemsPerPage);

  useEffect(() => {
    if (!isPaused) {
      const interval = setInterval(() => {
        setCurrentPage(prevPage => (prevPage + 1) % totalPages);
      }, 10000); 

      return () => clearInterval(interval); // Cleanup on unmount
    }
  }, [isPaused, totalPages]);

  const handleLeftClick = () => {
    setCurrentPage(prevPage => (prevPage === 0 ? totalPages - 1 : prevPage - 1));
  };

  const handleRightClick = () => {
    setCurrentPage(prevPage => (prevPage + 1) % totalPages);
  };

  const handleMouseEnter = () => {
    setIsPaused(true);
  };

  const handleMouseLeave = () => {
    setIsPaused(false);
  };

  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const visibleItems = items.slice(startIndex, endIndex);

  return (
    <div className="project-list-container">
      <div className="item-list">
        {visibleItems.map((item) => (
          <ProjectCard
            key={item.id}
            project={item}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          />
        ))}
      </div>
      <div className="nav-buttons">
        <button
          className="nav-button"
          onClick={handleLeftClick}
          disabled={items.length <= itemsPerPage}
        >
          Left
        </button>
        <button
          className="nav-button"
          onClick={handleRightClick}
          disabled={items.length <= itemsPerPage}
        >
          Right
        </button>
      </div>
    </div>
  );
};

ProjectList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
    link: PropTypes.string
  })).isRequired,
  itemsPerPage: PropTypes.number.isRequired
};

export default ProjectList;
