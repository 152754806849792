import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import EyeIcon from '@mui/icons-material/RemoveRedEyeOutlined';
// import CommentIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import styles from './Post.module.scss';
import UserInfo from '../UserInfo/UserInfo';
import PropTypes from 'prop-types';

const Post = ({
  id,
  title,
  createdAt,
  imageUrl,
  user,
  viewsCount,
  commentsCount,
  tags,
  children,
  isFullPost,
  isEditable,
}) => {
  return (
    <div className={clsx(styles.root, { [styles.rootFull]: isFullPost })}>
      {isEditable && (
        <div className={styles.editButtons}>
          {/* Edit and Delete buttons */}
        </div>
      )}
      {imageUrl && (
        <img
          className={clsx(styles.image, { [styles.imageFull]: isFullPost })}
          src={imageUrl}
          alt={title}
        />
      )}
      <div className={styles.wrapper}>
        <UserInfo {...user} additionalText={createdAt} />
        <div className={styles.indention}>
          <h2 className={clsx(styles.title, { [styles.titleFull]: isFullPost })}>
            {isFullPost ? (
              title
            ) : (
              <Link to={`/posts/${id}`} className={styles.postLink}>
                {title}
              </Link>
            )}
          </h2>
          <ul className={styles.tags}>
            {tags &&
              tags.length > 0 &&
              tags.map((name) => (
                <li key={name}>
                  <Link to={`/tag/${name}`} className={styles.tagLink}>
                    {name}
                  </Link>
                </li>
              ))}
          </ul>
          {children && <div className={styles.content}>{children}</div>}
          <ul className={styles.postDetails}>
            <li>
              <EyeIcon />
              <span>{viewsCount}</span>
            </li>
            {/* <li>
              <CommentIcon />
              <span>{commentsCount}</span>
            </li> */}
          </ul>
        </div>
      </div>
    </div>
  );
};

Post.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  user: PropTypes.shape({
    name: PropTypes.string.isRequired,
    avatarUrl: PropTypes.string,
  }).isRequired,
  viewsCount: PropTypes.number.isRequired,
  commentsCount: PropTypes.number.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string).isRequired,
  children: PropTypes.node,
  isFullPost: PropTypes.bool,
  isEditable: PropTypes.bool,
};

export default Post;
