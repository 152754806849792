import React from 'react';
import { useNavigate } from 'react-router-dom'; 
import '../../styles/Button/AboutBottun.css'

const AboutButton = () => {
  const navigate = useNavigate(); // Initialize navigate

  const handleClick = () => {
    // Use navigate('/about') to navigate to the "/about" route
    navigate('/about');
  };

  return (
    <button className='container-about-home'  onClick={handleClick}>
  <p>aboute me</p>
     
   
    <div className='union-svg-con'>
        <div className='union-svg-item'>
  <img src='img/Union.svg' alt='union1' className='img-slaq' />
</div>



      </div>

</button>
  );
};

export default AboutButton;
