// reportWebVitals.js

const reportWebVitals = onPerfEntry => {
  if (onPerfEntry && typeof onPerfEntry === 'function') {
    import('web-vitals').then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
      getCLS(metric => {
        onPerfEntry('CLS', metric);
      });
      getFID(metric => {
        onPerfEntry('FID', metric);
      });
      getFCP(metric => {
        onPerfEntry('FCP', metric);
      });
      getLCP(metric => {
        onPerfEntry('LCP', metric);
      });
      getTTFB(metric => {
        onPerfEntry('TTFB', metric);
      });
    });
  }
};

export default reportWebVitals;
