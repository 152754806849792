import React from 'react';
import { useNavigate } from 'react-router-dom'; 
import '../../styles/Button/ServicesButton.css'

const ServicesButton = () => {
  const navigate = useNavigate(); // Initialize navigate

  const handleClick = () => {
    // Use navigate('/about') to navigate to the "/about" route
    navigate('/services');
  };

  return (
      <div className='button-services' onClick={handleClick}>
      <div className='services-text-con-1'>
        <div className='services-text-1'>services</div>
        </div>
        <div className='services-text-con-2'>
        <div className='services-text-2'>
        Welcome to our services!
         We are dedicated to providing top-notch solutions to meet your needs.
          </div>
        </div>
        <div className='services-svg-con'>
<div className='servive-svg-item'>
  <img src='img/Group23.svg' alt='freelance' />
</div>
        </div>
        
      </div>
  );
};

export default ServicesButton;