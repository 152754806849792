import React from 'react';
import { useNavigate } from 'react-router-dom'; 
import '../../styles/Button/MyProjectsButton.css';

const MyProjectsButton = () => {
  const navigate = useNavigate(); // Initialize navigate

  const handleClick = () => {
    // Use navigate('/about') to navigate to the "/about" route
    navigate('/myprojects');
  };

  return (
    <div className='button-projects' onClick={handleClick}>
      <div className='text-con-1'>
        <div className='text-1'>my projects</div>
      </div>
      <div className='text-con-2'>
        <div className='text-2'>Welcome to my portfolio! Here,.<br/> 
        I showcase my work and projects, sharing my passion and dedication to creating amazing solutions.<br/>
     
       </div>
      </div>
      <div className="picture-containe-p">
        <div className="profile-picture-p">
          <img src="img/Group17.svg" alt="developer" className='developer' />
        </div>
      </div>
    </div>
  );
};

export default MyProjectsButton;
