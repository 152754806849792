import React from 'react'
import Services from './Services'

const ServicesPage = () => {
  return (
    <div>
        <Services />
    </div>
  )
}

export default ServicesPage